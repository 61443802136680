import { preload } from './module/load';

(function init() {
  const mainfest = [
    // Libraries bundle
    // { src: './js/vendor.js' },
    // Images
    { src: './images/ARROW_BTN.png' },
    { src: './images/BG.jpg' },
    { src: './images/BG_COLOR.png' },
    { src: './images/LOGO.png' },
    { src: './images/HOME_LOGO2.png' },
    { src: './images/HOME_LOGO.png' },
    { src: './images/MENU.png' },
    { src: './images/MENU_BG1.png' },
    { src: './images/MENU_CLOSE.png' },
    { src: './images/MENU_FB.png' },
    { src: './images/MENU_IG.png' },
    { src: './images/TV.png' },
    { src: './images/PLANE_BG.png' },
    { src: './images/workshop1.jpg' },
    { src: './images/workshop2.jpg' },
    { src: './images/workshop3.jpg' },
    { src: './images/workshop4.jpg' },
    { src: './images/workshop5.jpg' },
    { src: './images/workshop6.jpg' },
    { src: './images/NEW_BG.jpg' },
  ];

  function showPhoto(e) {
    let currentSrc = e.target.style.backgroundImage;
    // currentSrc = currentSrc.split('url("')[1];
    // currentSrc = currentSrc.split('")')[0];
    console.log(currentSrc);
    // return;
    // $('.show-image .content-block').css('background-image', `url(${currentSrc})`);
    $('.show-image .content-block').css('background-image', currentSrc);
    $('.show-image').show();
    $('.close-image-btn').off();
    $('.close-image-btn').click(() => {
      $('.show-image').hide();
    });
  }

  function setPhotoblockAnimate() {
    const photos = document.querySelectorAll('.photowall-block .photoblock');
    photos.forEach((photo) => {
      photo.addEventListener('mouseenter', (e) => {
        const { target } = e;
        target.classList.remove('maskFadeout');
        target.classList.add('maskFadeIn');
      });
      photo.addEventListener('mouseleave', (e) => {
        const { target } = e;
        target.classList.add('maskFadeout');
        target.classList.remove('maskFadeIn');
      });
    });
  }

  function setOpenWallClick() {
    const photos = document.querySelectorAll('.photowall-block .photoblock');
    photos.forEach((photo) => {
      console.log('photo: ', photo);
      photo.addEventListener('click', (e) => {
        console.log('click=======: ', e.target.className);
        showPhoto(e);
      });
    });
  }

  async function getCurrentData() {
    const formData = new FormData();
    formData.append('filter_type', 'photowall');
    try {
      const AXIOS = window.axios;
      const config = {
        url: `../api/get_media_datum.php?time=${+new Date()}`,
        method: 'POST',
        data: formData,
      };
      const response = await AXIOS.request(config);
      if (!response) return;
      const responseData = response.data;
      const {
        status,
      } = responseData;
      if (status === 'success') {
        const {
          medias,
        } = responseData.data;
        const isHaveMedia = medias.length;
        let tempDom = '';
        $('.media-list').text('');
        if (isHaveMedia === 0) return;
        medias.forEach((media, index) => {
          // https://vimeo.com/471739347
          const mediaType = media.type;
          const mediaUrl = media.url;
          const mediaFilename = media.filename;
          let tempMedia = '';
          const depths = [0.45, 0.55, 0.65, 0.55, 0.95, 0.85, 0.75, 0.83];
          // eslint-disable-next-line prefer-destructuring
          if (mediaType === 'video') tempMedia = mediaUrl.split('https://vimeo.com/')[1];
          if (mediaType === 'video') {
            tempDom = `<div data-depth="${depths[index]}" class="photoblock photoblock-${index + 1} layer p-video"><iframe src="https://player.vimeo.com/video/${tempMedia}?autoplay=1&muted=1&loop=1&autopause=0" allow=autoplay width="100%" height="100%" frameborder="0" scrolling="no" allow="autoplay; fullscreen" allowfullscreen="allowfullscreen"></iframe></div>`;
            $('.photowall-block').append(tempDom);
          } else {
            tempDom = `<div data-depth="${depths[index]}" class="photoblock photoblock-${index + 1} layer p-photo">`;
            $('.photowall-block').append(tempDom);
            const currentPhoto = document.querySelector(`.photoblock.photoblock-${index + 1}`);
            console.log(currentPhoto);
            currentPhoto.style.backgroundImage = `url(../uploads/${mediaFilename})`;
          }
          const scene = document.querySelector('#scene');
          const { Parallax } = window;
          const parallaxInstance = new Parallax(scene);
          scene.style.pointerEvents = 'auto';
          $('section.tv-click').css('z-index', '0');
          $('.photowall-block .photoblock-1').css('left', '-2.5%');
          $('.photowall-block .photoblock-1').css('top', '0%');
          // $('.photowall-block .photoblock-1').css('z-index', '3');
          $('.photowall-block .photoblock-2').css('left', '37.5%');
          $('.photowall-block .photoblock-2').css('top', '-10%');
          $('.photowall-block .photoblock-3').css('left', '67.5%');
          $('.photowall-block .photoblock-3').css('top', '15%');
          $('.photowall-block .photoblock-4').css('left', '2.5%');
          $('.photowall-block .photoblock-4').css('top', '35%');
          $('.photowall-block .photoblock-5').css('left', '77.5%');
          $('.photowall-block .photoblock-5').css('top', '55%');
          $('.photowall-block .photoblock-6').css('left', '7.5%');
          $('.photowall-block .photoblock-6').css('top', '65%');
          $('.photowall-block .photoblock-7').css('left', '32.5%');
          $('.photowall-block .photoblock-7').css('top', '20%');
          $('.photowall-block .photoblock-8').css('left', '47.5%');
          $('.photowall-block .photoblock-8').css('top', '60%');
        });
        $('.photowall-block').show();
        setOpenWallClick();
        setPhotoblockAnimate();
      }
    } catch (error) {
      console.log('error!! ', error);
      throw new Error(error);
    }
  }

  function openPhotoWall(timer1) {
    clearTimeout(timer1);
    $('section.photowall-page').addClass('up-zindex');
    $('section.tv-level').hide();
    $('section.tv-click').hide();
    $('section.tv-click').css('z-index', '0');
    getCurrentData();
  }

  function loadComplete() {
    console.log('Load Complete!!');
    $('.main-block').fadeOut(3000);
    $('section.photowall-page').fadeIn(1000);
    $('section.main-page').hide();
    $('button.menu').show();
    const timer1 = setTimeout(() => {
      $('section.tv-level').show().fadeOut(500).fadeIn(500)
        .fadeOut(500)
        .fadeIn(500);
      $('section.tv-click').show();
      $('section.tv-click').click(() => { openPhotoWall(timer1); });
    }, 2000);
    // $('section.tv-level').click(() => { openPhotoWall(timer1); });
    $('button.close-menu').off();
    // eslint-disable-next-line prefer-arrow-callback
    $('button.menu').click(function menu() {
      $('button.menu').hide();
      $('button.close-menu').show();
      $('section.menu-content').fadeIn(500);
    });
    $('button.close-menu').off();
    // eslint-disable-next-line prefer-arrow-callback
    $('button.close-menu').click(function closeMenu() {
      $('button.menu').show();
      $('button.close-menu').hide();
      $('section.menu-content').fadeOut(500);
    });
  }

  function loadProgress(event, assignPercent, isComplete) {
    const progressFront = document.querySelector('.progressbar-front');
    const loadedPercent = assignPercent || Math.ceil(event.loaded * 100);
    progressFront.style.width = `${loadedPercent}%`;
    if (isComplete) loadComplete();
  }

  if (mainfest.length > 0) preload.startPreload(mainfest, loadProgress, null, loadComplete);
  else loadProgress(null, 100, true);

}());
