function startPreload(mainfest, progressCallback, fileloadCallback, completeCallback, haveSound) {
  const { createjs } = window;
  const queue = new createjs.LoadQueue(true);
  // 載入音源需要使用
  if (haveSound) queue.installPlugin(createjs.SOUND);
  // 開始載入資源中的事件監聽.只會執行一次
  // queue.addEventListener('loadstart', loadstartCallback || this.handleLoadStart);
  // 載入資源中的事件監聽
  queue.addEventListener('progress', progressCallback || this.handleFileProgress);
  // 載入檔案的事件監聽
  queue.addEventListener('fileload', fileloadCallback || this.handleFileLoad);
  // 佇列資源全部載完事件
  queue.addEventListener('complete', completeCallback || this.loadComplete);
  // 設定最大同時連線數(最大值為10)
  queue.setMaxConnections(1);
  // 載入已先宣告的資源檔
  queue.loadManifest(mainfest);
}

function handleFileProgress(event) {
  const loadingSvgName = '.loading-block';
  const loadingFrontCircle = document.querySelector(`${loadingSvgName} circle.front-circle`);
  const loadingText = document.querySelector(`${loadingSvgName} text`);
  const loadedPercent = Math.ceil(event.loaded * 100);
  const dashOffset = 1100 - (loadedPercent * 11);
  loadingFrontCircle.setAttribute('stroke-dashoffset', dashOffset);
  loadingText.textContent = `${loadedPercent}%`;
}

function loadComplete() {
  console.log('Module: Load Complete');
}

function handleLoadStart() {
  console.log('Module: Load start');
}

function handleFileLoad(event) {
  console.log('Module: FileLoad', event);
}

const preload = {
  startPreload,
  // 顯示的佇列載入中的進度事件的處理函數
  // 開始載入檔案的處理函數
  handleLoadStart,
  handleFileProgress,
  // 載入檔案的處理函數
  handleFileLoad,
  // 佇列資源全部載完後的處理函數
  loadComplete,
};

const preload2 = {
  startPreload,
};

export { preload };
export { preload2 };
